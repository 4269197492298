import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/home/">Strona główna</a>
                </li>
                <li>
                  <a href="/produkty/">Produkty</a>
                </li>
                <li>
                  <a href="/poradnik/">Poradnik</a>
                </li>
                <li>
                  <a href="/dobierz-lek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/pytania-i-odpowiedzi/">Pytania i odpowiedzi</a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Strona główna</a>
            <a href="/polityka-cookies-aneks/" className="sel">
              {" "}
              &gt; Aneks Polityki cookies
            </a>
          </div>
          <div id="page_content">
            <h1 className="uc">
              Rodzaje plików cookie używanych przez tę stronę
            </h1>
            <div className="wysiwyg">
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <strong>
                  I.Pliki „cookie” generowane przez JNTL Consumer Health (Poland) sp. z o.o.
                </strong>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <strong>&nbsp;</strong>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <strong>&nbsp;</strong>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <strong>
                  <em>
                    1. Pliki „cookie” niezbedne do zapewnienia podstawowych
                    funkcji strony
                  </em>
                </strong>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>&nbsp;</u>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Źródło:</u> <em>JNTL Consumer Health (Poland) sp. z o.o.</em>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Przeznaczenie:</u>{" "}
                <em>
                  Pliki „cookie” tego rodzaju sa niezbedne do wlasciwego
                  funkcjonowania naszej strony i zapewniaja jej bezpieczne i
                  wydajne dzialanie.
                </em>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Okres, przez jaki plik jest przechowywany:</u>{" "}
                <em>
                  Pliki te sa automatycznie usuwane z dysku uzytkownika po
                  uplywie miesiaca od ostatniej wizyty na naszej stronie
                  internetowej.
                </em>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Sposób blokowania pliku:</u>{" "}
                <em>
                  <em>
                    Aby włączyć lub wyłączyc obsługe tych plików „cookie”,
                    należy odpowiednio zmienić ustawienia przeglądarki
                    internetowej. Ponieważ sposób zmiany tych ustawień jest inny
                    w każdej przeglądarce, bliższych informacji należy szukać w
                    systemie pomocy używanej przeglądarki
                  </em>
                  i.
                </em>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <em>&nbsp;</em>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <strong>
                  <em>2. Pliki „cookie” poprawiajace funkcjonalnosc strony</em>{" "}
                </strong>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>&nbsp;</u>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Źródło:</u> <em>JNTL Consumer Health (Poland) sp. z o.o.</em>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Przeznaczenie:</u>{" "}
                <em>
                  Pliki te przyczyniaja sie do zwiekszenia wydajnosci i
                  uzytecznosci naszej stron internetowej. Moga na przyklad pomóc
                  w ustaleniu, czy przegladarka obsluguje male programy
                  uruchamiane przez przegladarke (tzw. skrypty), zapewniajace
                  dodatkowa funkcjonalnosc, a takze czy pozwalaja zapamietac
                  zmiany w konfiguracji strony internetowej wprowadzone przez
                  uzytkownika (np. wielkosc czcionki lub jezyk).
                </em>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Okres, przez jaki plik jest przechowywany:</u>{" "}
                <em>
                  Generowane w tym celu pliki „cookie” sa automatycznie usuwane
                  z urzadzenia uzytkownika po zamknieciu przegladarki.
                </em>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Sposób blokowania pliku:</u>{" "}
                <em>
                  Aby włączyć lub wyłączyc obsługe tych plików „cookie”, należy
                  odpowiednio zmienić ustawienia przeglądarki internetowej.
                  Ponieważ sposób zmiany tych ustawień jest inny w każdej
                  przeglądarce, bliższych informacji należy szukać w systemie
                  pomocy używanej przeglądarki.
                </em>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                &nbsp;
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <br />
                <strong>
                  II. Pliki „cookie” oraz inne mechanizmy gromadzenia informacji
                  o uzytkowniku wykorzystywane przez uslugodawców obslugujacych
                  firme JNTL Consumer Health (Poland) sp. z o.o. oraz przez inne
                  firmy
                </strong>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <strong>&nbsp;</strong>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <strong>&nbsp;</strong>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <em>
                  <strong>
                    1. Pliki „cookie” i inne technologie używane do celów
                    analizy
                  </strong>
                </em>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <strong>&nbsp;</strong>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Źródło:</u> <em>Google Analytics.</em>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Przeznaczenie:</u>{" "}
                <em>
                  Pliki „cookie” tego typu uzywane sa do zbierania informacji o
                  tym, jak uzytkownicy korzystaja z naszej strony. Uzywamy tych
                  informacji do sporzadzania raportów i ulepszania strony. Dane
                  zbierane przy pomocy tych plików „cookie” maja charakter
                  anonimowy; informuja one o liczbie osób odwiedzajacych strone,
                  o tym, jak trafili na te strone oraz jakie podstrony ogladali.
                </em>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Okres, przez jaki plik jest przechowywany:</u>{" "}
                <em>
                  Niektóre z plików „cookie” generowanych w tym celu sa
                  automatycznie usuwane z urzadzenia uzytkownika po zamknieciu
                  przegladarki. Inne moga byc przechowywane przez okres
                  nieprzekraczajacy 24 miesiecy od ostatniej wizyty na naszej
                  stronie.
                </em>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Sposób blokowania pliku:</u>&nbsp;Informacje o tym, jak
                zablokowac gromadzenie danych przez Google Analytics mozna
                znalezc na stronie{" "}
                <a
                  href="https://tools.google.com/dlpage/gaoptout?hl=en-GB"
                  target="_blank"
                >
                  https://tools.google.com/dlpage/gaoptout?hl=en-GB
                </a>
                .
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <br />
                &nbsp;
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <em>
                  <strong>
                    2. Pliki „cookie” i technologie umożliwiajace korzystanie z
                    pewnych funkcji
                  </strong>
                </em>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <strong>&nbsp;</strong>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Źródło:</u>&nbsp;YouTube
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Przeznaczenie:</u>{" "}
                <em>
                  Pliki „cookie” tego rodzaju sluza udostepnianiu na naszej
                  stronie internetowej materialów, które dostarczane sa przez
                  strony trzecie. Bez tych plików pewne funkcje nie beda
                  dostepne dla odwiedzajacych nasza strone. Dane gromadzone w
                  tym celu przez uslugodawców, z którymi wspólpracujemy moga byc
                  udostepniane stronom trzecim na zasadzie anonimowej i moga byc
                  wykorzystywane w reklamie ukierunkowanej.
                </em>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Okres, przez jaki plik jest przechowywany:</u>{" "}
                <em>
                  Serwis YouTube wykorzystuje do tych celów pliki Adobe Flash
                  („Flash cookies”). Sa one zapisywane w urzadzeniu uzytkownika
                  na czas nieokreslony, ale mozna je usunac (patrz instrukcja
                  blokowania tego mechanizmu). Inne pliki „cookie” generowane w
                  tym celu przez serwis YouTube moga byc przechowywane przez
                  okres nieprzekraczajacy 8 miesiecy od ostatniej wizyty na
                  naszej stronie.
                </em>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Sposób blokowania pliku:</u>{" "}
                <em>
                  Aby wlaczyc lub wylaczyc obsluge plików „Flash cookie”, nalezy
                  wybrac odpowiednie ustawienia na stronie Adobe
                  <a
                    href="http://www.macromedia.com/support%20/documentation/en/flashplayer/help/settings_manager07.html"
                    target="_blank"
                  >
                    {" "}
                    http://www.macromedia.com/support
                    /documentation/en/flashplayer/help/settings_manager07.html
                  </a>
                  . Aby zablokowac gromadzenie danych przez serwis YouTube,
                  nalezy odwiedzic strone
                  <br />
                  <a
                    href="https://www.google.com/settings/u/0/ads/authenticated?hl=pl"
                    target="_blank"
                  >
                    https://www.google.com/settings/u/0/ads/authenticated?hl=pl.
                  </a>
                </em>
              </p>
              <p
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                &nbsp;
              </p>
              <div
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <strong>
                  3. Analityczne Cookies i technologie: s_fid, s_dl, s_cpm,
                  s_vnum, s_lv, s_vi
                </strong>
              </div>
              <div
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                &nbsp;
              </div>
              <div
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                <u>Źródło: </u>Adobe Analytics (poprzednio znane jako Adobe Site
                Catalyst &amp; Adobe Omniture)
                <br />
                <u>Przeznaczenie: </u>Adobe Analytics używa plików "cookies" do
                rozróżnienia pomiędzy różnymi użytkownikami strony oraz do
                sprawdzania co użytkownicy robią i na co patrzą na stronie.
                &nbsp;"Cookies" pozwalają nam podjąć i analizować informacje o
                odwiedzających, takich jak wykorzystanie przeglądarki, liczba
                nowych użytkowników, liczby zobaczonych stron na odwiedziny i
                reakcje na działania marketingowe.
              </div>
              <div
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                Adobe Analytics pozwala nam określić i zrozumieć punkty awarii w
                podróż po naszej stronie jako konsumenta, a dane, które zbieramy
                koncertują się nad doskonaleniem doświadczenia dla ciebie jako
                klienta. Przeglądanie tych informacji pomaga nam zidentyfikować
                sekcje, funkcjonalności i produkty na stronie internetowej,
                które pracują dobrze i są przydatne dla Ciebie jako konsumenta,
                jak również te, które nie działają tak dobrze, więc możemy dążyć
                do poprawy doświadczenia użytkownika. Adobe cookies dostarczają
                nam informacji, które pomagają nam poprawiać jakość usług
                świadczonych dla ciebie.
              </div>
              <div
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                Do danych zbieranych przez Adobe Analytics wgląd mają jedynie
                odpowiednie zespoły w Kenvue i Adobe.
                <br />
                <u>Okres, przez jaki plik jest przechowywany: </u>s_fid - 2
                lata; s_dl - 2 lata; s_cpm - 90 dni; s_vnum – 1 rok; s_lv - 1
                rok; s_vi - 5 lat
              </div>
              <div
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                Sposób blokowania pliku: Możesz zablokować pliki cookies tutaj:{" "}
                <a
                  href="http://metrics.cpgconsumer.com/optout.html"
                  target="_blank"
                >
                  http://metrics.cpgconsumer.com/optout.html
                </a>
                .
              </div>
              <div
                style={{
                  "-webkit-text-align": "justify",
                  "text-align": "justify"
                }}
              >
                Kenvue nie ponosi odpowiedzialności za zawartość
                stron zewnętrznych.
              </div>
            </div>
          </div>
          <footer>
            <div className="page_width">
              <div className="drug_alert"></div>
              <p
                style={{
                  "font-size": "10px",
                  "-webkit-text-align": "right",
                  "text-align": "right",
                  padding: "0 0 10px"
                }}
              >
                MAT/6769/03/2019
              </p>
            </div>
            <div className="footer_content">
              <div className="page_width">
                <ul id="footer_menu">
                  <li>
                    <a href="/polityka-prywatnosci/">Polityka prywatności</a>
                  </li>
                  <li>
                    <a href="/polityka-cookies/">Polityka cookies</a>
                  </li>
                  <li>
                    <a href="/nota-prawna/">Nota prawna</a>
                  </li>
                  <li>
                    <a href="/mapa-strony/">Mapa strony</a>
                  </li>
                  <li>
                    <a href="/slowniczek/">Słowniczek</a>
                  </li>
                  <li>
                    <a id="ot-sdk-btn" className="ot-sdk-show-settings" rel="nofollow"> Ustawienia plików Cookie </a>
                  </li>
                </ul>
                <div className="rel footer_info">
                  <br />
                  Podmiot odpowiedzialny: McNeil Products Limited <br />©
                  JNTL Consumer Health (Poland) sp. z o.o. 2023
                </div>
              </div>
            </div>
          </footer>{" "}
        </div>
      </div>
    );
  }
}

export default Page;
